import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Banner from "../../components/case-studies/Banner"
import bannerData from "../../content/case-studies-list/banner.json"
import detailsData from "../../content/case-studies-list/details.json"
import CaseStudiesList from "../../components/case-studies/CaseStudiesList"
import MetaTags from "react-meta-tags"
import SEO from "../../components/seo"

const caseStudies = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Case Studies | Cresol Infoserv"
        description="Discover how Cresol Infoserv has helped businesses achieve success through innovative digital solutions. Explore our case studies and see the results."
        keywords="best website development company, website design and development services, best digital marketing services, software testing services, mobile app development company"
      />
      {/* <Banner image={bannerData.bgImage} title={bannerData.heading} /> */}
      <CaseStudiesList headings={detailsData} list={data.caseStudies} />
    </Layout>
  )
}

export default caseStudies

export const pageQuery = graphql`
  query CaseStudiesQuery {
    caseStudies: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "case-studies-details" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            homePageDetails {
              image {
                publicURL
              }
              heading
              description
            }
          }
        }
      }
    }
  }
`
