import React from "react"
import StyledCaseStudiesList from "./style/case-studies-list"
import Heading from "../common/Heading"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FaArrowRight } from "react-icons/fa"

const CaseStudiesList = ({ list, headings }) => {
  var settings = {
    autoplay: false,
    dots: true,
    infinite: list.edges.length < 6 ? false : true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <StyledCaseStudiesList>
      <div className="main-container">
        {/* <Heading text={headings.heading} color="" /> */}
        <h2 className="text-white">{headings.heading}</h2>
        <p className="sub-heading">{headings.subHeading}</p>
        <ul className="portfolio-list">
          <Slider {...settings}>
            {list.edges.map((d, i) => (
              <li className="portfolio-item" key={i}>
                <div className="image">
                  <img
                    src={d.node.frontmatter.homePageDetails.image.publicURL}
                    alt={d.node.frontmatter.homePageDetails.heading}
                  />
                </div>
                <div className="info">
                  <a
                    className="name"
                    href={d.node.frontmatter.slug}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span> {d.node.frontmatter.homePageDetails.heading}</span>
                  </a>
                  <p className="description">
                    {d.node.frontmatter.homePageDetails.description}
                  </p>
                  <a
                    className="button"
                    href={d.node.frontmatter.slug}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span> Explore</span>
                    <FaArrowRight />
                  </a>
                </div>
              </li>
            ))}
          </Slider>
        </ul>
      </div>
    </StyledCaseStudiesList>
  )
}

export default CaseStudiesList
